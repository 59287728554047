import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  sendingOTP: false,
  error: '',
  business_segment_type: null,
  customer_id: null,
};

const VERIFY_CUSTOMER = 'VERIFY_CUSTOMER';
const VERIFY_CUSTOMER_COMPLETED = 'VERIFY_CUSTOMER_COMPLETED';
const VERIFY_CUSTOMER_FAILED = 'VERIFY_CUSTOMER_FAILED';

const SEND_OTP = 'SEND_OTP';
const SEND_OTP_COMPLETED = 'SEND_OTP_COMPLETED';
const SEND_OTP_FAILED = 'SEND_OTP_FAILED';

const CLEAR_BUSINESS_SEGMENT_DATA = 'CLEAR_BUSINESS_SEGMENT_DATA';

export const handleClearBussinessSegment = (searchData) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_BUSINESS_SEGMENT_DATA,
      payload: searchData,
    });
  };
};

export const sendOTP = (customer_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.sendOTPURL(),
        data: {customer_id},
      });
      console.log('response --SEND_OTP--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: SEND_OTP_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: SEND_OTP_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: SEND_OTP_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const verifyCustomer = (customer_id, otp) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: VERIFY_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.verifyCustomerURL(),
        data: {customer_id, otp},
      });
      console.log('response --VERIFY_CUSTOMER_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: VERIFY_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: VERIFY_CUSTOMER_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: VERIFY_CUSTOMER_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case VERIFY_CUSTOMER:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case VERIFY_CUSTOMER_COMPLETED:
      let customer_id = payload?.customer_ids[0];
      let businessSegment =
        payload?.customers[customer_id]?.basicInfo?.business_segment;
      return {
        ...state,
        business_segment_type: businessSegment,
        customer_id: payload?.customer_ids[0],
        isFetching: false,
        hasError: false,
      };
    case VERIFY_CUSTOMER_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        business_segment_type: null,
      };
    case SEND_OTP:
      return {...state, sendingOTP: true};
    case SEND_OTP_COMPLETED:
    case SEND_OTP_FAILED:
      return {...state, sendingOTP: false};

    case CLEAR_BUSINESS_SEGMENT_DATA:
      return {
        ...state,
        business_segment_type: null,
      };

    default:
      return state;
  }
};
